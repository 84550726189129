/* password strength meter */
.password-strength-container {
  input[type="password"] {
    margin-bottom: 5px;
  }
}

/* password show/hide */
.hideShowPassword-toggle {
  margin-right: 5px;
  border: none;
  font-size: 12px;
  text-transform: uppercase;
  line-height: 19px;
  background: #f1f2f5;
  color: darken(#f1f2f5, 20%);

  &:focus {
    outline: 0;
  }

  &.toggle-eye {
    background: transparent;

    i {
      position: relative;
      top: 1px;
      font-size: 14px;
    }
  }
}