.btn-outline-light {
  color: inherit;
  border-color: darken($light, 10%);

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  .show > &.dropdown-toggle {
    border-color: darken($light, 10%);
  }

  &:hover,
  &:focus {
    border-color: darken($light, 15%);
  }

  &.disabled, 
  &:disabled {
    color: inherit;
  }
}