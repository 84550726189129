/********************
 * WIDGETS
 ********************/

 .widget {
  padding: 20px 25px;
  margin-bottom: 30px;
  border: 1px solid $border-panel-color;
  background: #fff;
  border-radius: 3px;
}

.widget-mini-bar {
  text-align: center;

  .mini-bar-chart {
    margin-bottom: 5px;
  }

  span {
    font-size: 15px;
    color: $text-muted;
    line-height: 1;
  }
}

.widget-task-progress {
  padding-top: 30px;
  padding-bottom: 30px;

  .top {
    margin-bottom: 10px;
  }

  .percentage {
    float: right;
    font-size: 36px;
    font-weight: 300;
    line-height: 1;
  }

  .title {
    margin-bottom: 5px;
    color: $body-color;
    line-height: 2.4;
  }

  .progress {
    margin-bottom: 0;
  }

  .bottom {
    margin-top: 8px;
    font-size: 13px;
    color: $text-muted;

    a {
      color: $text-muted;
      text-decoration: underline;

      &:hover,
      &:focus {
        text-decoration: none;
      }
    }

    .note {
      float: right;
    }
  }
}

.widget-mini-realtime-usage {
  .chart {
    height: 60px;
  }

  .usage-info {
    margin-top: 10px;

    .title,
    .value {
      display: block;
    }

    .title {
      font-size: 11px;
      color: $text-muted;
    }

    .value {
      font-size: 14px;
    }
  }

  .left {
    float: left;
  }

  .right {
    float: right;
  }
}

// widget metrics
.widget-metric_1 {
  .icon-wrapper {
    display: block;
    position: relative;
    width: 50px;
    height: 50px;
    float: left;
    text-align: center;
    color: #fff;
    overflow: hidden;
    border-radius: 50%;

    i {
      font-size: 17px;
      line-height: 50px;
    }

    &:after {
      display: block;
      position: absolute;
      top: 23px;
      left: 4px;
      content: "";
      background-color: rgba(#fff, .2);
      width: 130%;
      height: 100%;

      -webkit-transform: rotate(-30deg);
      -moz-transform: rotate(-30deg);
      transform: rotate(-30deg);
    }
  }

  .right {
    padding-left: 65px
  }

  .value {
    margin-right: 5px;
    font-size: 24px;
    font-weight: 300;
  }

  .change-icon {
    position: relative;
    left: 2px;
    font-size: 18px;
  }

  .fa-sort-down {
    top: -6px;
  }

  .title {
    display: block;
    font-size: 12px;
    color: $text-muted;
  }

  &.animate:hover {
    .icon-wrapper {
      @include animate(pulse, .3s, 2);
    }

    .change-up {
      @include animate(fadeOutUp, 1s, infinite);
    }

    .change-down {
      @include animate(fadeOutDown, 1s, infinite);
    }
  }
}

.widget-metric_2 {
  .icon {
    float: left;
    font-size: 30px;
    color: $text-muted;
  }

  .right {
    padding-left: 60px;

    span {
      display: block;
    }
  }

  .title {
    font-size: 13px;
    color: $text-muted;
  }

  .value {
    margin: 8px 0;
    font-size: 28px;
    font-weight: 300;
    line-height: 1;
  }

  .percentage {
    font-size: 15px;
  }

  .menu {
    line-height: 2.2;
  }

  &.animate:hover {
    .icon {
      @include animate(bounceIn, 1s, 1);
    }
  }
}

.list-widget-vertical .widget-metric_2 {
  margin: 35px 0;
  padding: 0 25px;
}

.widget-metric_3 {
  text-align: center;

  .value {
    font-size: 46px;
    font-weight: 300;
    display: block;
  }

  .note {
    font-size: 13px;
    color: $text-muted;
  }
}

.widget-metric_4 {
  text-align: center;

  .chart {
    display: block;
    margin: 12px 0;
  }

  .note {
    font-size: 13px;
    color: $text-muted;
  }
}

.widget-metric_5 {
  text-align: center;

  .list-value {
    margin: 8px 0;

    > li {
      margin: 0 10px;
    }
  }

  i {
    font-size: 28px;
    color: $text-muted;
  }

  .percentage {
    display: block;
    font-size: 13px;
  }

  .note {
    font-size: 13px;
    color: $text-muted;
  }
}

.widget-metric_6 {
  .icon-wrapper {
    @include border-radius(8px);

    display: block;
    position: relative;
    width: 50px;
    height: 50px;
    float: left;
    text-align: center;
    color: #fff;
    overflow: hidden;

    i {
      font-size: 20px;
      line-height: 50px;
    }
  }

  .right {
    padding-left: 65px
  }

  .value {
    margin-right: 5px;
    font-size: 24px;
    font-weight: 300;
  }

  .title {
    display: block;
    font-size: 12px;
    color: $text-muted;
  }

  &.animate:hover {
    .icon-wrapper {
      @include animate(tada, 1s, 1);
    }
  }

  @include max-screen($break-xsmall) {
    margin-bottom: 15px;
  }
}

.widget-metric_7 {
  .icon {
    float: left;
    font-size: 22px;
  }

  .right {
    padding-left: 40px;

    span {
      display: block;
    }
  }

  .title {
    font-size: 13px;
    color: $text-muted;
  }

  .value {
    margin-bottom: 5px;
    font-size: 15px;
    line-height: 1;
  }
}

.widget-metric_8 {
  .heading {
    margin-bottom: 15px;
  }

  .title {
    float: left;
    line-height: 1.8;
  }

  .dropdown {
    display: block;
    float: right;
  }

  .toggle-dropdown {
    border-radius: 50%;
    width: 23px;
    height: 23px;
    line-height: 20px;
    display: block;
    border: 1px solid #c6d2dd;
    text-align: center;
    font-size: 16px;
    color: #c6d2dd;

    &:hover,
    &:focus {
      background-color: #f9fafb;
    }

    i {
      margin: 0;
      font-size: 14px;
    }
  }

  i {
    margin-right: 5px;
    font-size: 21px;
  }

  .value {
    font-size: 23px;
    font-weight: 300;
  }

  .progress {
    margin-top: 5px;
    margin-bottom: 15px;
  }

  .info {
    margin: 0;
    color: $text-muted;
    font-size: 12px;
  }

  .icon-change {
    font-size: 12px;
  }
}

.widget-metric_9 {
  .title {
    display: block;
    line-height: 1;
    font-size: 28px;
    font-weight: 300;
    color: $text-muted;
  }

  .value {
    display: block;
    line-height: 1.6;
    font-size: 48px;
    font-weight: 300;
  }

  .percentage {
    display: block;
    font-size: 15px;
    margin-bottom: 3px;

    i {
      font-size: 24px;
      position: relative;
      top: 4px;
      vertical-align: middle;
    }

    .icon-down {
      top: -8px;
    }
  }
}

.widget-metric_10 {
  text-align: center;

  .title {
    display: block;
    font-size: 16px;
  }

  .value {
    display: block;
    margin: 3px 0;
  }

  .number {
    font-size: 24px;
  }

  .symbol {
    font-size: 15px;
    color: $text-muted;
  }
}

.widget-metric_11 {
  .bottom {
    margin-top: 10px; 
  }

  .right {
    line-height: 4.3;
    text-align: right;
  }

  .value {
    display: block;
    font-size: 28px;
    font-weight: 300;
  }

  .change {
    color: $text-muted;
  }
}


.list-widget-vertical {
  margin: 0;

  > li {
    border-bottom: 1px solid $list-separator-color;

    &:last-child {
      border-bottom: none;
    }
  }

  &.no-border {
    > li {
      border-bottom: none;
      margin-bottom: 40px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &.spacing-25 {
      > li {
        margin-bottom: 25px;
      }
    }
  }
}


.list-widget-horizontal {
  margin: 0;

  > li {
    border-right: 1px solid $list-separator-color;

    &:last-child {
      border-right: none;
    }
  }

  &.no-border {
    > li {
      border-right: none;
    }
  }

  @include max-screen($break-small) {
    > li {
      border-right: none;
      border-bottom: 1px solid $list-separator-color;
      padding: 25px 0;

      &:last-child {
        border-bottom: none;
      }
    }
  }
}

.quick-note {
  @include border-radius(3px);

  background: #fff;
  padding: 20px;
  cursor: pointer;
  margin-bottom: 30px;
  border: 1px solid #e6e6e6;

  .quick-note-title {
    margin-top: 0;
    font-size: 16px;
  }
}

.quick-note-create {
  .card-footer {
    display: none;
  }
}

.quick-note-edit {
  margin-bottom: 0;
}

/* widget stat */
.widget-stat {
  padding: 25px 30px;

  .media-left {
    padding-right: 15px;

    i {
      @include border-radius(3px);

      display: block;
      width: 50px;
      height: 50px;
      line-height: 50px;
      text-align: center;
      font-size: 18px;
    }
  }

  .title {
    color: $text-muted;
  }

  .value {
    display: block;
    font-size: 26px;
  }

  .footer {
    margin-top: 15px;
    margin-bottom: 0;
    padding-top: 12px;
    border-top: 1px solid #f0f0f0;

    span {
      color: $text-muted;
      font-size: 13px;
      margin-left: 8px;
    }
  }
}

/* profile completeness meter */
.list-complete-profile {
  margin-bottom: 0;

  > li {
    margin: 20px 0;

    &.done {
      color: $text-muted;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

/* file item */
.file-item {
  width: 100%;
  margin: 0 15px 15px 0;
  float: left;
  border: 1px solid $border-panel-color;
  background-color: #fff;
  border-radius: 3px;

  .file-preview {
    display: block;
    height: 146px;
    text-align: center;
    padding: 50px 20px;
    overflow: hidden;
    background-color: #eaeaea;
    color: darken(#eaeaea, 25%);

    i {
      background: darken(#eaeaea, 5%);
      padding: 10px;
      font-size: 24px;
      border-radius: 10px;
    }

    .file-extension {
      font-size: 32px;
    }
  }

  .file-info {
    padding: 12px 30px 15px 12px;
    position: relative;
    font-size: 14px;

    span {
      display: block;
      margin: 3px 0;
    }

    .file-name {
      color: $primary;
    }

    .file-date {
      font-size: 13px;
      color: $text-muted;
    }

    .toggle-dropdown {
      padding: 0 10px;
      color: $text-muted;
      font-size: 20px;

      i {
        display: inline;
      }
    }

    .dropdown {
      position: absolute;
      right: 5px;
      top: 50%;
      margin-top: -11px;
    }
  }
}

.file-preview {
  &.pdf {
    color: #fff;
    background-color: #AB7DF6;

    i {
      background: #fff;
      color: #AB7DF6;
    }
  }

  &.xls {
    color: #fff;
    background-color: #86d227;

    i {
      background: #fff;
      color: #86d227;
    }
  }

  &.doc {
    color: #fff;
    background-color: #20B2AA;

    i {
      background: #fff;
      color: #20B2AA;
    }
  }

  &.audio {
    color: #fff;
    background-color: #7183a2;

    i {
      background: #fff;
      color: #7183a2;
    }
  }

  &.video {
    color: #fff;
    background-color: #F3BB23;

    i {
      background: #fff;
      color: #F3BB23;
    }
  }

  &.image {
    padding: 0;
  }
}

.widget-system-load {
  padding-top: 30px;
  padding-bottom: 30px;

  .chart {
    width: 130px;
    position: absolute;
    float: left;
  }

  .info {
    padding-left: 170px;

    .heading-title {
      margin-top: 0;
      margin-bottom: 20px; 
      font-size: 16px;
      font-weight: normal;
    }

    .title {
      display: block;
      color: $text-muted;
      font-size: 13px;
    }

    .value {
      display: block;
      font-size: 22px;
    }
  }

  .controls {
    margin-top: 20px;
  }

  @include max-screen($break-xsmall) {
    .chart {
      float: none;
      position: relative
    }

    .info {
      padding-left: 0;
    }
  }
}

// list of project with thin progress bar
.list-project-progress {
  .project-name {
    display: block;
    margin-bottom: 10px;

    &:hover,
    &:focus {
      color: #fff;
    }
  }

  .progress {
    width: 85%;
    height: 5px;
    margin-bottom: 1rem;
    float: left;
  }

  .percentage {
    float: right;
    position: relative;
    top: -9px;
    color: lighten($sidebar-bg-color, 15%);
  }
}

// list of files with icons
.list-file-simple {
  margin-bottom: 0;
  
  a {
    color: $body-color;

    &:hover,
    &:focus {
      text-decoration: underline;

      i {
        text-decoration: none;
      }
    }
  }

  i {
    margin-right: 8px;
  }

  span {
    color: $text-muted;
    font-size: 13px;
  }
}

// rating
.rating {
  .number {
    font-size: 55px;
    font-weight: 300;
    margin-right: 15px;
    line-height: 1;
  }

  .rating-stars {
    position: relative;
    top: -10px;
  }
}

.rating-stars {
  i {
    font-size: 22px;
    color: #FFB500;
  }

  &.smaller i {
    font-size: 16px;
  }

  .info {
    margin-left: 5px;
    font-size: 13px;
    color: $text-muted;

    a {
      color: $text-muted;

      &:hover,
      &:focus {
        text-decoration: underline;
      }
    }
  }
}

/* leaderboard */
.leaderboard {
  .tab-pane {
    padding: 0;
  }
}

.list-positions {
  margin: 0;

  .position {
    border-bottom: 1px solid $list-separator-color;
    padding: 25px;

    &:last-child {
      border-bottom: 0;
    }

    .change {
      float: left;
      line-height: 55px;
      min-width: 50px;
    }

    .icon-change {
      position: relative;
      font-size: 22px;
    }

    .user {
      float: left;
      position: relative;

      .position-number {
        border-radius: 50%;
        display: block;
        position: absolute;
        bottom: -6px;
        right: -8px;
        width: 28px;
        height: 28px;
        line-height: 22px;
        color: #fff;
        font-size: 12px;
        text-align: center;
        border: 2px solid #fff;
        background-color: $primary;
      }
    }

    .user-picture {
      border-radius: 50%;
      width: 55px;
      height: 55px;
      border: 2px solid transparent;

      &.anonymous {
        display: block;
        line-height: 50px;
        text-align: center;
        font-size: 24px;
        background-color: #eff4fa;
      }
    }

    .account {
      float: left;
      padding-left: 5px;

      .right {
        padding: 10px 3px 10px 75px;
        height: 55px;
      }

      .name {
        display: block;
        line-height: 1;
        color: $body-color;

        &:hover,
        &:focus {
          text-decoration: underline;
        }
      }

      .username {
        font-size: 13px;
        color: $text-muted;
      }
    }

    .points {
      border-radius: 2em;
      float: right;
      position: relative;
      top: 11px;
      padding: 6px 12px;
      font-size: 17px;
      background-color: #f0f2f9;
      color: #7b8198;
    }

    &.up {
      .icon-change {
        top: 8px;
        color: $color-indicator-green;
      }

      .account .user-picture {
        border-color: $color-indicator-green;
      }
    }

    &.down {
      .icon-change {
        top: -2px;
        color: $color-indicator-red;
      }

      .account .user-picture {
        border-color: $color-indicator-red;
      }
    }

    @include max-screen($break-xsmall) {
      .change {
        display: none;
      }

      .account {
        padding-left: 0;
      }

      .points {
        font-size: 14px;
      }
    }
  }
}

/* to-do list */
.todo-list {
  > li {
    position: relative;
    padding: 15px 0;
    font-size: 14px;

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
    }

    .fancy-checkbox {
      float: left;
    }

    p {
      margin: 0;
      padding-left: 35px;
    }

    &.completed p,
    &.completed .date {
      text-decoration: line-through;
      color: $text-muted;
    }
  }
}

/* list contacts */
.list-contacts {
  margin-bottom: 0;

  > li {
    @include clearfix;

    margin-bottom: 30px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .media {
    float: left;
    position: relative;
  }

  .picture {
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    color: #fff;
    border-radius: 50%;
  }

  .status {
    display: block;
    width: 12px;
    height: 12px;
    position: absolute;
    top: 0;
    right: -2px;
    border: 2px solid #fff;
    background-color: $text-muted;
    border-radius: 50%;

    &.online {
      background-color: #53c265;
    }
  }

  .info {
    float: left;
    padding-left: 15px;

    .name,
    .email {
      display: block;
    }

    .email,
    .title {
      color: $text-muted;
      font-size: 13px;
    }
  }

  .controls {
    float: right;
    position: relative;
    top: 7px;

    a {
      margin-left: 8px;
      color: $text-muted;
      font-size: 20px;

      &:hover,
      &:focus {
        color: $text-muted;
      }
    }
  }

  @include max-screen($break-xsmall) {
    .controls {
      display: none;
    }
  }
}

/* activity list */
ul.activity-list {
  > li {
    padding: 25px 0;
    font-size: 14px;
    border-bottom: 1px solid $list-separator-color;

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      border-bottom: none;
    }

    .avatar {
      width: 40px;
    }

    > p {
      margin-bottom: 0;
      padding-left: 3.5em;
    }

    .timestamp {
      display: block;
      font-size: 13px;
      color: $text-muted;
    }
  }
}

/* task list */
.task-list {
  > li {
    margin-bottom: 32px;
  }

  p {
    margin-bottom: .5rem;
    position: relative;
    color: $text-muted;
    font-size: 14px;

    .label-percent {
      position: absolute;
      right: 0;
    }
  }
}

// resume
.widget-resume {
  .resume-header,
  .resume-footer {
    border-color: #e9eef2;
  }

  .resume-header {
    @include clearfix;

    border-bottom-width: 1px;
    border-bottom-style: solid;

    .left {
      float: left;
    }

    .right {
      float: right;
    }
  }

  .profile-image {
    border-radius: 3px;
    width: 50px;
    height: 50px;
    float: left;
  }

  .bio-text {
    padding-left: 65px;
    margin-bottom: 30px;

    .name {
      display: block;
      font-size: 18px;
      color: inherit;

      &:hover,
      &:focus {
        color: inherit;
        text-decoration: underline;
      }
    }

    .title {
      color: $text-muted;
      font-weight: 300;
    }
  }

  .resume-footer {
    border-top-width: 1px;
    border-top-style: solid;
    padding-top: 25px;
  }

  .skills {
    .label-text {
      font-size: 15px;
      color: $text-muted;
      line-height: 2;
    }
  }

  @include max-screen($break-xsmall) {
    .resume-header .right .btn {
      span {
        display: none;
      }
    }
  }
}

.list-resume-experience {
  @include clearfix;

  margin: 0;
  
  .experience {
    @include clearfix;

    margin: 30px 0;
    position: relative;

    .company-logo {
      float: left;
      border-radius: 50%;
      width: 50px;
      height: 50px;
      line-height: 4;
      text-align: center;

      i {
        font-size: 22px;
        color: #fff;
      }
    }

    .detail {
      padding-left: 65px;
    }

    .company-name {
      display: block;
      font-size: 17px;
    }

    .title {
      color: $text-muted;
      font-weight: 300;
    }

    &:after {
      content: "";
      display: block;
      border-left: 1px dashed #d2dbe2;
      width: 1px;
      height: 25px;
      position: relative;
      top: 15px;
      left: 25px;
    }

    &:last-child:after {
      border: none;
      height: 0;
    }
  }

  .left {
    float: left;
  }

  .period {
    float: right;
    position: relative;
    top: 15px;
    font-size: 13px;
    font-weight: 300;
    color: $text-muted;
  }
}

// top support agents
.support-agent {
  .agent-photo {
    border-radius: 3px;
    width: 60px;
    height: 60px;
    float: left;
  }

  .agent-detail {
    padding-left: 75px;
  }

  .name {
    display: block;
    font-size: 15px;
    color: inherit;
    line-height: 1;
    margin-bottom: 3px;

    &:hover,
    &:focus {
      color: inherit;
      text-decoration: underline;
    }
  }

  .email {
    display: block;
    margin-bottom: 5px;
    font-size: 13px;
    color: $text-muted;
  }
}