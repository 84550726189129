.card {
  margin-bottom: 30px;

  .card-title,
  .card-header {
    margin: 0;
    font-size: .9rem;
    font-weight: 400;
  }

  .card-subtitle {
    color: $text-muted;
  }

  .card-info {
    font-size: 12px;
    color: $text-muted;
    line-height: 1.6;
  }
}

.card-headline {
  .card-header {
    background-color: #fff;
    border-bottom: 0;
  }
}

.card-header {
  .btn {
    &:focus,
    &:focus:active,
    &:not(:disabled):not(.disabled):active:focus {
      box-shadow: none;
      outline: 0;
    }
  }
    
  .btn-toggle-dropdown {
    padding: 0;
    font-size: 16px;
    color: #c6d2dd;
  }

  .btn-toggle-collapse,
  .btn-remove {
    padding: 0;
    margin-left: 5px;
    background-color: transparent;
    border: none;
    outline: none;

    i {
      font-size: 14px;
      color: #c6d2dd;
    }
  }

  .link {
    i {
      margin-right: 3px;
      font-size: 13px;
      color: $text-muted;
    }

    a {
      color: $text-muted;
      font-size: 12px;

      &:hover,
      &:focus {
        text-decoration: underline;
      }
    }
  }

  &.no-divider {
    border-bottom: none;
    background-color: transparent;
  }
}

.card-footer {
  background-color: #fff;
  border-color: #eaeff2;
}

.card-quick-note {
  .card-body {
    padding: 0;
  }

  input.title,
  textarea {
    border: none;
    resize: none;
    padding-left: 25px;
    padding-right: 25px;
    box-shadow: none;
  }

  input.title {
    font-weight: 700;
  }

  textarea {
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }

  .card-footer {
    display: flex;
    justify-content: space-between;
  }
}

.overlay-refresh {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-size: 18px;
  text-align: center;
  background: rgba(246, 246, 246, .88);

  i {
    display: block;
  }
}

.scrollable {
  overflow: auto;
}

// card with sidebar
.card-sidebar {
  .card-body {
    padding: 0;
  }

  .left,
  .right {
    padding: 25px;
  }

  .left {
    float: left;
  }

  .right {
    float: right;
  }

  &.sidebar-right {
    .left {
      width: 66.66666667%;
      border-right: 1px solid #eaeff2;
    }

    .right {
      width: 33.33333333%;
    }
  }

  &.sidebar-left {
    .left {
      width: 33.33333333%;
    }

    .right {
      width: 66.66666667%;
      border-left: 1px solid #eaeff2;
    }
  }

  .sidebar-section {
    margin-bottom: 30px;
  }

  .section-title {
    margin: 0 0 15px 0;
    font-size: 15px;
    font-weight: 600;
  }

  @include max-screen($break-xsmall) {
    &.sidebar-right,
    &.sidebar-left {
      .left,
      .right {
        float: none;
        width: 100%;
      }
    }
  }
}

.card-layout {
  @include clearfix;

  margin-left: -30px;
  margin-right: -30px;

  .card-cols-2 {
    width: 50%;
    float: left;
    position: relative;
    padding-left: 30px;
    padding-right: 30px;
  }

  &.dashed-separator {
    div[class^="card-cols"]:after {
      content: "";
      border: 1px dashed $list-separator-color;
      position: absolute;
      right: 0;
      height: 100%;
      top: 0;
    }

    div[class^="card-cols"]:last-child:after {
      border: none;
    }
  }

  @include max-screen($break-xsmall) {
    div[class^="card-cols"] {
      float: none;
      width: 100%;
      margin-bottom: 50px;
    }
  }
}