@mixin border-right-radius($right-radius) {
	-moz-border-radius-topright: $right-radius;
	-webkit-border-top-right-radius: $right-radius;
	border-top-right-radius: $right-radius;
	-moz-border-radius-bottomright: $right-radius;
	-webkit-border-bottom-right-radius: $right-radius;
	border-bottom-right-radius: $right-radius;
}

@mixin border-left-radius($left-radius) {
	-moz-border-radius-topleft: $left-radius;
	-webkit-border-top-left-radius: $left-radius;
	border-top-left-radius: $left-radius;
	-moz-border-radius-bottomleft: $left-radius;
	-webkit-border-bottom-left-radius: $left-radius;
	border-bottom-left-radius: $left-radius;
}

@mixin border-top-radius($top-radius) {
	-moz-border-radius-topleft: $top-radius;
	-webkit-border-top-left-radius: $top-radius;
	border-top-left-radius: $top-radius;
	-moz-border-radius-topright: $top-radius;
	-webkit-border-top-right-radius: $top-radius;
	border-top-right-radius: $top-radius;
}

@mixin border-bottom-radius($bottom-radius) {
	-moz-border-radius-bottomleft: $bottom-radius;
	-webkit-border-bottom-left-radius: $bottom-radius;
	border-bottom-left-radius: $bottom-radius;
	-moz-border-radius-bottomright: $bottom-radius;
	-webkit-border-bottom-right-radius: $bottom-radius;
	border-bottom-right-radius: $bottom-radius;
}

@mixin animate($animate-class, $duration, $count) {
	-webkit-animation-duration: $duration;
	animation-duration: $duration;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;

	-webkit-animation-name: $animate-class;
	animation-name: $animate-class;
	-webkit-animation-iteration-count: $count;
	animation-iteration-count: $count;
}


