// icon with transparent container
.icon-transparent-area {
  border-radius: 8px;
  width: 70px;
  height: 70px;
  margin: 0 auto;
  position: relative;
  text-align: center;
  border

  &.first {
    margin-left: 0;
  }

  &.last {
    float: right;
  }

  i {
    margin-top: 1em;
    font-size: 18px;
  }

  span {
    display: block;
  }

  .badge {
    border-radius: 50%;
    position: absolute;
    top: -7px;
    right: -7px;
    width: 26px;
    height: 26px;
    padding: 0;
    font-weight: normal;
    line-height: 20px;
    border: 2px solid #fff;
    color: #fff;
  }
}

@each $color-name, $color-value in $custom-colors {
  @include icon-transparent-area-variant($color-name, $color-value);
}

// icon with light container
.icon-app {
  border-radius: 6px;
  display: block;
  width: 55px;
  height: 55px;
  text-align: center;
  border: 1px solid #eaeaea;
  background-color: #fff;

  i {
    line-height: 55px;
    font-size: 36px;
  }
}