/********************
 * PAGE ERRORS
 ********************/

 .page-error {
  max-width: 45rem;
  text-align: center;

  .title {
    display: flex;
    justify-content: center;
  }

  .number {
    font-size: 8rem;
    
    &.left {
      margin-right: 5px;
    }
  }

  .title {
    @include max-screen($break-small) {
      flex-direction: column;
    }
  }

  .text {
    text-align: left;
    font-size: 3.2rem;
    line-height: 1.2;
    position: relative;
    top: 12px;

    @include max-screen($break-small) {
      margin-bottom: 40px;
    }
  }

  .number.left,
  .text {
    @include max-screen($break-small) {
      text-align: center;
    }

    @include max-screen($break-xsmall) {
      font-size: 1.4em;
    }
  }

  .searchbox {
    margin: 50px auto;

    @include max-screen($break-xsmall) {
      input[type="search"],
      .input-group,
      .input-group-btn,
      .btn {
        display: block;
        width: 100%;
        float: none;
      }
    }
  }
}


