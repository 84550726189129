/* bootstrap inputs */
.custom-control-inline {
  margin-right: 0;
}

.custom-control-inline + .custom-control-inline {
  margin-left: 10px;
}

.custom-control-label {
  line-height: 1.6;
}

/* fancy checkbox */
.fancy-checkbox {
  margin-right: 10px;

  &,
  label {
    font-weight: normal;
  }

  input[type="checkbox"] {
    display: none;
    
    + span {
      display: inline-block;
      cursor: pointer;
      position: relative;

      &:before {
        display: inline-block;
        vertical-align: middle;
        font-family: FontAwesome;
        color: #97a3b7;
        text-align: center;
        border-radius: 3px;
        position: relative;
        bottom: 1px;
        width: 20px;
        height: 20px;
        line-height: 20px;
        margin-right: 5px;
        content: "";
        border: 1px solid #dbdfe2;
        font-size: 11px;
      }
    }

    &:checked + span:before {
      content: '\f00c';
      background: #f1f4f7;
    }
  }
  
  &.custom-color-green input[type="checkbox"] {
    &:checked + span:before {
      color: #53D76A;
      background-color: #fff;
    }
  }

  &.custom-bgcolor-green input[type="checkbox"] {
    &:checked + span:before {
      color: #fff;
      background-color: #53D76A;
      border-color: darken(#53D76A, 8%);
    }
  }
}

/* fancy radio */
.fancy-radio {
  margin-right: 10px;

  &,
  label {
    font-weight: normal;
  }

  input[type="radio"] {
    display: none;
    
    + span {
      display: block;
      cursor: pointer;
      position: relative;

      i {
        display: inline-block;
        border-radius: 8px;
        vertical-align: middle;
        position: relative;
        bottom: 1px;
        content: "";
        border: 1px solid #dbdfe2;
        width: 18px;
        height: 18px;
        margin-right: 5px;
      }
    }

    &:checked + span i:after {
      border-radius: 50%;
      display: block;
      position: relative;
      top: 3px;
      left: 3px;
      content: "";
      width: 10px;
      height: 10px;
      background-color: #97a3b7;
    } 
  }

  &.custom-color-green input[type="radio"] {
    &:checked + span i:after {
      background-color: #53D76A;
    }
  }

  &.custom-bgcolor-green input[type="radio"] {
    &:checked + span i {
      background-color: #53D76A;
      
      &:after {
        background-color: #fff;
      } 
    }
  }
}

.input-group-prepend,
.input-group-append {
  .fancy-radio,
  .fancy-checkbox {
    margin: 0;
    position: relative;
    top: 1px;
  }

  .fancy-radio input[type="radio"] + span i,
  .fancy-checkbox input[type="checkbox"] + span {
    margin: 0;

    &:before {
      margin: 0;
    }
  }
}

/* switch checkbox and radio */
.switch-input {
  display: block;
  position: relative;
  line-height: 1;
  margin-bottom: 4px;
  padding: 10px 60px 0 0;
  cursor: pointer;
  font-weight: normal;

  input {
    position: absolute;
    left: -9999px;

    &:checked + i {
      border-color: darken(#53D76A, 15%);
      background-color: #53D76A;

      &:before {
        right: 33px;
        background-color: darken(#53D76A, 15%);
      }

      &:after {
        content: attr(data-swon-text);
        text-align: right;
        color: #fff;
      }
    }
  }

  i {
    @include border-radius(20px);

    content: '';
    display: block;
    position: absolute;
    top: 6px;
    right: 0;
    min-width: 50px;
    height: 20px;
    border-width: 1px;
    border-style: solid;
    border-color: $input-border-color;
    background: #f8f9fa;
    font-style: normal;

    &:before {
      @include border-radius(50%);
      @include transition(right .1s ease-in);

      content: '';
      display: block;
      position: absolute;
      top: 4px;
      right: 4px;
      width: 10px;
      height: 10px;
      background-color: darken(#f8f9fa, 20%);
    }

    &:after {
      content: attr(data-swoff-text);
      position: absolute;
      top: 2px;
      right: 8px;
      left: 8px;
      font-weight: normal;
      font-size: 10px;
      line-height: 15px;
      color: lighten($body-color, 20%);
    }
  }
}

/* parsley js */
.parsley-errors-list {
  margin-top: 8px;
  padding-left: 0;
  list-style-type: none;
  
  li {
    color: $danger;
    font-size: 0.9em;
    margin-top: 3px;
  }
}

/* multiselect */
.multiselect + .dropdown ul.multiselect-container {
  > li > a {
    padding: 3px 20px;

    label {
      padding-left: 0;
    }
  }
}

.multiselect-container .input-group {
  margin: 0;
}

/* multiselect checkbox */
.multiselect-custom + .dropdown ul.multiselect-container {
  > li {
    > a {
      label.checkbox {
        &:before {
          display: inline-block;
          vertical-align: middle;
          position: relative;
          bottom: 1px;
          content: "";
          width: 16px;
          height: 16px;
          margin-right: 10px;
          border: 1px solid #ccc;
          background-color: #fafafa;
        }

        input[type="checkbox"] {
          display: none;
        }
      }
    }

    &.active > a {
      label.checkbox:before {
        font-family: FontAwesome;
        content: '\f00c';
        font-size: 12px;
        color: #99A1A7;
        text-align: center;
        line-height: 15px;
        background-color: #EDEDED;
      }
    }
  }

}

/* multiselect radio */
.multiselect-custom + .dropdown ul.multiselect-container {
  > li {
    > a {
      label.radio {
        &:before {
          border-radius: 8px;
          position: relative;
          bottom: 1px;
          content: "";
          width: 16px;
          height: 16px;
          margin-right: 10px;
          border: 1px solid #ccc;
          background-color: #fafafa;
          display: inline-block;
          vertical-align: middle;
          border-radius: 8px;
        }

        input[type="radio"] {
          display: none;
        }
      }
    }

    &.active > a {
      label.radio:before {
        font-family: FontAwesome;
        content: '\f111';
        color: #99a1a7;
        font-size: 8px;
        text-align: center;
        line-height: 15px;
      }
    }
  }
}
.multiselect-search {
  @include border-right-radius(2px !important);
}

.btn.multiselect-clear-filter {
  position: absolute;
  top: 2px;
  right: 5px;
  padding-left: 0;
  padding-right: 0;
  border: none;
  background: transparent;
  z-index: 9 !important;
  outline: none;
  box-shadow: none;

  &:hover,
  &:focus {
    background: transparent;
    outline: none;
  }
}

// bootstrap datepicker
.datepicker table tr td {
  &.active {
    &,
    &:hover,
    &.disabled,
    &.disabled:hover {
      background-color: #609dea !important;
      background-image: none;
    }
  }

  &.selected,
  &.selected.highlighted {
    background-color: #a6b7c5;
  }

  &.range {
    background-color: #e9eef2;
  }

  &.today {
    &,
    &:hover,
    &:focus {
      background-color: #ff4402;
      color: #fff;
    }
  }
}

// bootstrap colorpicker
.colorpicker-element .input-group-append {
  &:focus {
    outline: 0;
  }
}

/* slider input by noUiSlider */
.noUi-target {
  border-color: $input-border-color;
  background: #fcfcfc;
  border-radius: 3px;
  box-shadow: none;
}

.noUi-connect {
  background: #45AEEF;
  box-shadow: none;
}

.noUi-handle {
  border-color: $input-border-color;
  box-shadow: none;

  &:before,
  &:after {
    height: 10px;
    top: 5px;
  }

  &:before {
    left: 9px;
  }

  &:after {
    left: 12px;
  }

  &:hover {
    cursor: pointer;
  }
}

.noUi-horizontal,
.noUi-vertical {
  height: 12px;

  .noUi-handle {
    width: 23px;
    height: 23px;

    &:focus {
      outline: 0;
    }
  }
}

/* bootstrap slider input */
.slider-track,
.slider-selection {
  @include border-radius(0);
  @include box-shadow(none);

  background-image: none;
  filter: none; /* disable gradient for IE */
}

.slider-track {
  background-color: #eee;
}

.slider-selection {
  background-color: $secondary;
}

.slider.slider-horizontal {
  width: 100% !important;
}

.slider.slider-vertical {
  .slider-handle {
    position: relative;
    left: 2px;
    cursor: ns-resize;
  }
}

.slider-handle {
  background-image: none;
  position: relative;
  top: 2px;
  border-radius: 0;
  box-shadow: none;
  opacity: 1;
}

/* handle for bootstrap slider and range slider */
.slider-handle {
  width: 16px;
  height: 16px;
  background: #fff;
  border: 1px solid $secondary;
  cursor: col-resize;

  &:before {
    display: inline-block;
    content: '|||';
    font-size: 7px;
    color: $secondary;
    text-align: center;
    line-height: 15px;
    position: absolute;
    left: 0.6em;
  }
}

/* select2 */
.select2-container {
  &--focus {
    outline: 0;
  }

  .select2-selection--single {
    height: $input-height;

    &:focus {
      outline: 0;
    }
  }

  &--default {
    .select2-selection {
      &--single {
        border-color: $input-border-color;
        background-color: #fcfcfc;
  
        .select2-selection__rendered {
          color: $body-color;
          line-height: $input-height;
        }

        .select2-selection__arrow {
          height: $input-height;
        }

        .select2-selection__placeholder {
          color: $text-muted;
        }
      }

      &--multiple {
        border-color: $input-border-color;

        .select2-selection__choice {
          border-color: $input-border-color;
          background-color: #f2f2f5;
          color: darken(#f2f2f5, 40%);
      
          .select2-selection__choice__remove {
            color: darken(#f2f2f5, 10%);
          }
        }
      }
    }

    .select2-search--dropdown .select2-search__field {
      border-color: $input-border-color;

      &:focus {
        outline: 0;
      }
    }

    &.select2-container--focus .select2-selection--multiple {
      border-color: $input-border-color;
    }
  }
}

.select2-container--default {
  .select2-results__option[aria-selected=true] {
    &,
    &:hover {
      background-color: #f2f2f5;
      color: $body-color;
    }
  }

  

  &.select2-container--disabled .select2-selection {
    background-color: #f5f5f5;
  }
}

.select2-dropdown {
  border-color: $input-border-color;
}

/* jeditable */
.editable-wrapper {
  display: flex;
  align-items: center;
}

.editable {
  &[title] {
    text-decoration: underline dashed;
    color: $link-color;
    cursor: pointer;
  }
}

.form-group .editable-wrapper {
  line-height: 2.4;
}

/* datepicker (jquery-ui) */
.ui-datepicker {
  .ui-widget-header {
    background-color: transparent;
    border: none;

    .ui-icon {
      background: none;
      text-indent: 0;
      font-size: 1rem;

      &:before {
        display: block;
      }
    }
  }

  .ui-datepicker-prev {
    left: 0;

    .ui-icon:before {
      content: "\000AB";
    }
  }

  .ui-datepicker-next {
    right: 0;

    .ui-icon:before {
      content: "\000BB";
    }
  }

  .ui-datepicker-prev,
  .ui-datepicker-next {
    top: 3px;
    width: auto;
    height: auto;
    text-align: center;

    &:hover {
      border: 0;
      background-color: transparent;
      cursor: pointer;
    }
  }

  .ui-datepicker-prev span, 
  .ui-datepicker-next span {
    position: relative;
    top: 0;
    left: 0;
    margin: 0;
  }

  .ui-state-default, 
  .ui-widget-content .ui-state-default, 
  .ui-widget-header .ui-state-default, 
  .ui-button, html .ui-button.ui-state-disabled:hover, 
  html .ui-button.ui-state-disabled:active {
    border: none;
    background-color: #fff;
    text-align: center;
    border-radius: 2px;
  }

  &.ui-widget.ui-widget-content .ui-state-active {
    background-color: $primary;
    color: #fff;
  }
}

.search-form {
  position: relative;

  .btn {
    position: absolute;
    top: 7px;
    right: 12px;
    padding: 0;
    border: none;
    background-color: transparent;
    box-shadow: none;

    i {
      margin-right: 0;
    }

    &:hover,
    &:focus {
      background-color: inherit;
      border-left: none;
      border-color: inherit;
    }
  }
}