/********************
 * RIGHT SIDEBAR
 ********************/

.right-sidebar {
  position: fixed;
  top: 0;
  padding-top: 5px;
  padding-bottom: 50px;
  overflow-y: auto;
  top: 55px;
  right: -360px;
  width: 350px;
  height: 100%;
  z-index: 99;
  background: $sidebar-bg-color;
  color: #f1f1f1;
  transition: right .3s ease-in-out;
  box-shadow: -5px 0 10px rgba(0,0,0,.08);

  &.active {
    right: 0;
  }

  .widget-heading {
    display: inline-block;
    margin: 0 0 10px 0;
    font-size: 12px;
    font-weight: 600;

    i {
      margin-right: 5px;
      font-size: 16px;
    }
  }

  .widget-header {
    padding: 15px 25px;
    margin: 0 -25px 30px -25px;
    background-color: darken($sidebar-bg-color, 1%);
    border-top: 1px solid darken($sidebar-bg-color, 2%);
    border-bottom: 1px solid darken($sidebar-bg-color, 2%);

    .widget-heading {
      margin: 0;
    }

    .show-all {
      float: right;
      font-size: 12px;
      line-height: 1.8;
      color: #a0aeba;
      text-decoration: underline;

      &:hover,
      &:focus {
        text-decoration: none;
      }
    }
  }

  .date {
    margin-bottom: 0;
    font-size: 20px;
    font-weight: 300;
  }

  .icon-transparent-area .badge{
    border-color: $sidebar-bg-color;
  }
  
  .list-project-progress {
    .project-name,
    .percentage {
      color: #f1f1f1;
    }
  }

  .list-file-simple {
    a {
      &,
      &:hover,
      &:focus{
        color: #f1f1f1;
      }
    }

    span {
      color: #f1f1f1;
    }
  }

  .btn-primary {
    background-color: darken($sidebar-bg-color, 4%);
    border-color: darken($sidebar-bg-color, 6%);
    color: #f1f1f1;
    box-shadow: none;
  
    &:hover,
    &:focus {
      background-color: darken($sidebar-bg-color, 6%);
      border-color: darken($sidebar-bg-color, 8%);
    }
  
    &.active,
    &:active {
      &:hover,
      &:focus {
        border-color: darken($sidebar-bg-color, 8%);
        color: #f1f1f1;
      }
    }
  }
}

.sidebar-widget {
  padding: 15px 25px;
}