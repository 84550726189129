/* chartist */
@import "./node_modules/chartist/dist/scss/settings/chartist-settings";

// Text styles for labels
$ct-text-color: #676a6d;

// Line chart properties
$ct-line-width: 3px;
$ct-point-size: 8px;

// Area fill transparency between 0 and 1
$ct-area-opacity: 0.3;

// Series names and colors. This can be extended or customized as desired. Just add more series and colors.
$ct-series-names: (a, b, c, d, e, f, g, h, i, j, k, l, m, n, o);
$ct-series-colors: (
  #028fd0,
  #83c6ea,
  #f4c63d,
  #d17905,
  #453d3f,
  #59922b,
  #0544d3,
  #6b0392,
  #f05b4f,
  #dda458,
  #eacf7d,
  #86797d,
  #b2c326,
  #6188e2,
  #a748ca
);

@import "./node_modules/chartist/dist/scss/chartist";


/* flot chart */
.demo-flot-chart {
  width: 100%;
  height: 350px;
}

.flot-donut-label {
  padding: 5px 10px;
  background-color: rgba(0, 0, 0, .7);
  color: #fff;
}

/* sparkline and flot tooltip */
.jqstooltip {
  border-radius: 2px !important;
}

.flotTip {
  background-color: #f4f7f9 !important;
  border-color: darken(#f4f7f9, 10%) !important;

  .jqsfield {
    color: $body-color;
  }
}

.flotTip-blue {
  background-color: rgba(#4286c9, .8) !important;
  border-color: #3c7fc1 !important;
  color: #fff;

  .jqsfield {
    color: #fff;
  }
}

.flotTip-black {
  background-color: rgba(#181818, .8) !important;
  border: none !important;
  color: #fff;

  .jqsfield {
    color: #fff;
  }
}

.legendLayer {
  fill: #fff;

  text {
    fill: $body-color;
  }
}

/* sparkline chart */
.sparkline-stat-medium {
  padding: 8px 0;
  margin-bottom: 30px;

  strong {
    display: block;
    font-size: 1.5em;
    font-weight: 300;
  }
}

/* sparkline bar chart */
.sparkbar-stat-item {
  .mini-bar-chart {
    position: relative;
    top: -10px;
    left: 8px;
  }
}

/* sparkline big chart */
.sparkline-stat-big {
  position: relative;
  padding: 5px 20px 0 20px;
  color: #506167;

  .summary {
    position: absolute;
  }

  .big-number {
    float: left;
    margin-right: 10px;
    font-size: 4em;
    font-weight: 300;
    line-height: .7;
  }
}

/* easy pie chart */
.easy-pie-chart {
  position: relative;
  margin: 0 auto;
  margin-bottom: 15px;
  text-align: center;

  .percent {
    display: inline-block;
    width: 100%;
    position: absolute;
    line-height: 130px;
    z-index: 2;
    font-size: 28px;
    text-align: center;
  }
}