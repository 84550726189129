/********************************
 * AUTHENTICATION FORMS, PAGE
 ********************************/

 .auth-box {
  display: flex;
  flex: 1 0 auto;
  max-width: 70rem;
  margin: 0 2rem;
  height: 30rem;
  background-color: #fff;
  box-shadow: 1px 2px 10px 0 rgba(#000, .1);
  border-radius: 5px;

  &:before {
    display: inline-block;
    content: ' ';
    vertical-align: middle;
    height: 100%;
  }
  
  .content {
    width: 100%;
  }

  .left {
    display: flex;
    align-items: center;
    width: 42%;
    padding: 0 30px;

    &:before {
      display: inline-block;
      content: ' ';
      vertical-align: middle;
      height: 100%;
    }
  }

  .right {
    display: flex;
    align-items: center;
    width: 58%;
    position: relative;
    background: {
      image: url('#{$assets_dir}images/login-bg.jpg');
      repeat: no-repeat;
      size: cover;
    }

    &:before {
      display: inline-block;
      content: ' ';
      vertical-align: middle;
      height: 100%;
    }
    
    .text {
      position: relative;
      padding: 0 30px;
      color: #fff;
    }

    .heading {
      margin-top: 0;
      margin-bottom: 5px;
      font-size: 26px;
      font-weight: 300;
    }

    p {
      margin: 0;
      font-size: 18px;
      font-weight: 300;
    }

    .overlay {
      position: absolute;
      top: 0;
      display: block;
      width: 100%;
      height: 100%;
      background: rgba(99,156,185,0.92);
    }
  }

  .header {
    margin-bottom: 30px;
  }

  .lead {
    margin-top: 5px;
    font-size: 18px;
    text-align: center;
  }

  .form-auth-small {
    .btn {
      margin-top: 20px;
    }

    .bottom {
      margin-top: 20px;
      text-align: center;
    }
  }

  .helper-text {
    font-size: 13px;
    color: $text-muted;
  }

  .btn-signin-social {
    display: block;
    margin-bottom: 15px;
    padding: 10px 22px;
    width: 100%;
    background-color: #fff;
    border-color: #e4ecf0;

    &:hover,
    &:focus {
      background-color: #fafafa;
    }

    i {
      font-size: 16px;
      margin: 5px;
    }
  }

  &.register,
  &.forgot-password,
  &.lockscreen {
    max-width: 30rem;
    height: auto;

    .content {
      padding: 30px;
    }
  }

  @include max-screen($break-1024) {
    .left {
      width: 100%;
    }

    .right {
      display: none;
    }
  }
}

