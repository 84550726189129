// mixins for mobile responsiveness

@mixin screen($res-min, $res-max) {
	@media screen and ( min-width: $res-min ) and ( max-width: $res-max ) {
		@content;
	}
}

@mixin max-screen($res) {
	@media screen and ( max-width: $res ) {
		@content;
	}
}

@mixin min-screen($res) {
	@media screen and ( min-width: $res ) {
		@content;
	}
}


