/********************
 * TABLES
 ********************/

 .table {
  &.table-minimal {
    > thead > tr {
      background-color: transparent;

      > th {
        padding: 10px 10px;
        color: $text-muted;
        border-top: none;
        border-bottom: none;
        font-size: 12px;
      }
    }

    > tbody > tr {
      > td {
        padding: 15px 10px;
        border-top: none;
        border-bottom: 1px solid $list-separator-color;
        vertical-align: middle;
      }

      &:last-child > td {
        border-bottom: none;
      }
    }
  }

  &.table-no-border {
    > thead > tr > th {
      padding: 15px 0;
      border-bottom: 1px solid $list-separator-color;
    }

    > tbody > tr {
      > td {
        padding: 7px 0;
        border: none;
      }

      &:first-child > td {
        padding-top: 15px;
      }
    }
  }
}

.card-body {
  .table-fullwidth {
    margin-bottom: 0;
  }
  
  .table-fullwidth > thead > tr > th,
  .table-fullwidth > tbody > tr > td {
    &:first-child,
    &:last-child {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}

/* table with icon and value only */
.table-icons-stat {
  > tbody > tr > td {
    color: #90a3b3;
    font-size: 13px;

    &:first-child {
      color: $body-color;
      font-size: 14px;
    }

    i {
      margin-right: 5px;
    }
  }
}

/* table without head and border */
.table-invisible {
  thead {
    display: none;
  }

  tbody {
    > tr > td {
      border: none;
      padding: 0 0 5px 0;
    }
  }
}

/* table responsive */
.table-responsive {
  .table > tbody > tr > td {
    white-space: nowrap;
  }
}
