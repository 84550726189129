/* sortable */
.ui-sortable {
  min-height: 35px;
}

.ui-sortable .card-header {
  cursor: move;
}

.ui-sortable-placeholder {
  border: 1px dashed #cecece !important;
  visibility: visible !important;
  background: #f3f3f3;
}

/* autocomplete */
.ui-widget.ui-widget-content {
  border-color: $input-border-color;
  color: $body-color;

  .ui-state-active {
    background: #f2f2f5;
    border-color: transparent;
    color: $body-color;
  }
}