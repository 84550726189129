.heading-border {
  display: inline-block;
  margin-bottom: 30px;
  padding-bottom: 5px;
  border-bottom: 1px solid #777;
  font-size: 24px;
  font-weight: normal;
}

.text-indicator-green {
  color: $color-indicator-green;
}

.text-indicator-red {
  color: $color-indicator-red;
}

.font-13 {
  font-size: 13px;
}

.font-12 {
  font-size: 12px;
}

.font-11 {
  font-size: 11px;
}

.help-block {
  color: $text-muted;
  font-size: 13px;
}

ul.list-justify > li {
  margin-bottom: 10px;

  > span {
    float: right;
  }
}
